<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

import http from "@/http";
import jsonToFormData from "@ajoelp/json-to-formdata";

import Header from "@/components/Header";
import Page from "@/baseComponents/Page";
import Card from "@/baseComponents/Card";
import CurrencyInput from "@/baseComponents/CurrencyInput";

import BriefcaseIcon from "vue-material-design-icons/BriefcaseEdit";
import ChevronDownIcon from "vue-material-design-icons/ChevronDown";
import ImagesIcon from "vue-material-design-icons/ImageMultipleOutline";
import CheckIcon from "vue-material-design-icons/Check";
import TrashIcon from "vue-material-design-icons/TrashCanOutline";
import LightBulbIcon from "vue-material-design-icons/LightbulbOn";

const swal = inject("$swal");
const router = useRouter();
const loading = ref(false);
const saving = ref(false);
const newProduct = ref(true);
const fileImage = ref("");
const loadingImage = ref(false);
const categories = ref([]);
const selectedCategories = ref([]);
const videoLink = ref("");
const videoToShow = ref("");
const productVideos = ref([]);
const productImages = ref([]);
const savingText = ref("");
const productMedias = ref([]);
const mediasToDelete = ref([]);

const product = ref({
  name: "",
  categoryIds: "",
  coverImage: "",
  description: "",
  orientation: "",
  price: 0,
  productMedia: [],
  type: "",
  summary: "",
  link: [],
  discount: 0,
});

const getProduct = () => {
  if (router.currentRoute.value.params.id != "novo") {
    newProduct.value = false;
    loading.value = true;

    http
      .get(`/product/details/?id=${router.currentRoute.value.params.id}`)
      .then((response) => {
        product.value = response?.data;
        productVideos.value =
          product.value.productMedia?.filter((el) => el.type == "Video") || [];
        productImages.value =
          product.value.productMedia?.filter((el) => el.type == "Imagem") || [];
        if (product.value.categoryIds)
          selectedCategories.value = product.value.categoryIds.split(",");

        productMedias.value = [
          ...productImages.value,
          ...productVideos.value,
        ].filter((media) => !media?.id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        loading.value = false;
      });
  }
};

const getcategories = () => {
  http.get("category/list").then((response) => {
    categories.value = response.data.list;
  });
};

getProduct();
getcategories();

const getFileImages = () => {
  if (!fileImage.value.files) return;
  loadingImage.value = true;
  for (let index = 0; index < fileImage.value.files.length; index++) {
    if (fileImage.value.files[index].size < 10485760) {
      const reader = new FileReader();
      reader.readAsDataURL(fileImage.value.files[index]);
      reader.onload = function (event) {
        if (event.target.readyState == FileReader.DONE) {
          const productImage = {
            data: fileImage.value.files[index],
            source: event.target.result,
            type: "Imagem",
            order: productImages.value.length + 1,
          };
          productImages.value.push(productImage);
        }
      };
    } else {
      fieldRequiredAlert("A imagem não pode ter mais de 10mb.");
    }
  }
  loadingImage.value = false;
};

const removeImage = (index, image) => {
  if (image.id) {
    mediasToDelete.value.push(image.id);
  }
  productImages.value.splice(index, 1);
  fileImage.value = "";
};

const setCoverImage = (image, index) => {
  [productImages.value[0], productImages.value[index]] = [
    productImages.value[index],
    productImages.value[0],
  ];
  productImages.value[0].order = 0;
  productImages.value[index].order = index;
  product.value.coverImage =
    image.source.substring(0, 4) === "http" ? image.source : "";
};

const isSelectedCategory = (categoryId) => {
  return selectedCategories.value.some((el) => el === categoryId);
};

const selectCategory = (categoryId) => {
  const selected = selectedCategories.value.find((el) => el === categoryId);

  if (!selected) selectedCategories.value.push(categoryId);
  else
    selectedCategories.value.splice(
      selectedCategories.value.indexOf(categoryId),
      1
    );
};

const getVideoCode = () => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = videoLink.value.match(regExp);
  videoToShow.value = {
    source: match && match[7].length == 11 ? match[7] : "",
    type: "Video",
  };
};

const addVideoToArray = () => {
  const found = productVideos.value.find(
    (element) => element.source == videoToShow.value.source
  );
  if (!found) {
    productVideos.value.push(videoToShow.value);
  }
};

const deleteVideo = (index) => {
  productVideos.value.splice(index, 1);
};

const checkProduct = () => {
  if (saving.value) return;

  if (!product.value.name) {
    fieldRequiredAlert("Por favor, insira o nome do produto/serviço.");
    return;
  }
  if (!product.value.type) {
    fieldRequiredAlert("Por favor, insira o tipo do produto/serviço.");
    return;
  }
  if (!product.value.summary) {
    fieldRequiredAlert("Por favor, insira o resumo do produto/serviço.");
    return;
  }
  if (!product.value.price) {
    fieldRequiredAlert("Por favor, insira o valor inicial do produto/serviço.");
    return;
  }
  if (
    isNaN(product.value.discount) ||
    !product.value.discount ||
    product.value.discount < 0 ||
    product.value.discount > 100
  ) {
    fieldRequiredAlert(
      "Por favor, insira uma porcentagem válida de desconto entre 0 e 100."
    );
    return;
  }
  if (!product.value.description) {
    fieldRequiredAlert("Por favor, insira a descrição do produto/serviço.");
    return;
  }
  if (!product.value.orientation) {
    fieldRequiredAlert("Por favor, insira a orientação do produto/serviço.");
    return;
  }
  if (product.value.type === "Produto" && productImages.value.length <= 1) {
    fieldRequiredAlert("Por favor, insira no mínimo 2 fotos para o produto.");
    return;
  }
  if (product.value.type === "Serviço" && productImages.value.length <= 7) {
    fieldRequiredAlert("Por favor, insira no mínimo 8 fotos para o serviço.");
    return;
  }
  if (selectedCategories.value.length <= 0) {
    fieldRequiredAlert(
      "Por favor, selecione uma categoria para o produto/serviço."
    );
    return;
  }

  product.value.categoryIds =
    selectedCategories.value.length > 0
      ? selectedCategories.value.toString()
      : "";

  productMedias.value = [...productImages.value, ...productVideos.value].filter(
    (media) => !media?.id
  );

  saving.value = true;
  newProduct.value ? createProduct() : updateProduct();
};

const setCreateCoverImage = () => {
  const existCoverImage = productMedias.value.some(
    (pm) => pm.order == 0 && pm.type === "Imagem"
  );

  if (!existCoverImage) {
    const imageMedias = productMedias.value.filter(
      (pm) => pm.type === "Imagem"
    );

    let minOrder = imageMedias[0].order;
    for (let i = 0; i < imageMedias.length; i++) {
      if (imageMedias[i].order < minOrder) {
        minOrder = imageMedias[i].order;
      }
    }

    productMedias.value = productMedias.value.map((pm) => {
      if (pm.type === "Imagem" && pm.order == minOrder) pm.order = 0;

      return pm;
    });

    console.log(productMedias.value);
  }
};

let mediaIndex = 0;
const saveMedias = () => {
  saving.value = true;
  const media = productMedias.value[mediaIndex];
  const objToSend =
    media.type === "Imagem"
      ? {
          productId: product.value.id,
          type: media.type,
          order: media.order,
          fileImage: {
            data: productMedias.value[mediaIndex].data,
            useAsCoverImage: media.order === 0,
          },
        }
      : {
          productId: product.value.id,
          type: media.type,
          order: mediaIndex,
          source: media.source,
          fileImage: {},
        };
  const objFormData = jsonToFormData(objToSend, { useDotSeparator: true });
  const config = { headers: { "Content-Type": "multipart/form-data" } };

  savingText.value = `Salvando suas mídias, aguarde: ${mediaIndex + 1}/${
    productMedias.value.length
  }`;

  http
    .post("product/media", objFormData, config)
    .then(() => {
      if (mediaIndex < productMedias.value.length - 1) {
        mediaIndex++;
        saveMedias();
      } else {
        // sucessAlert(`${product.value.type} salvo com sucesso!`);
        router.push({ path: "/produtos-servicos" });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const createProduct = () => {
  savingText.value =
    product.value.type === "Produto"
      ? "Salvando o produto, aguarde."
      : "Salvando o serviço, aguarde.";

  http
    .post("/product", product.value)
    .then((response) => {
      product.value = response.data;
      setCreateCoverImage();
      saveMedias();
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
      saving.value = false;
    });
};

const updateProduct = () => {
  savingText.value =
    product.value.type === "Produto"
      ? "Salvando o produto, aguarde."
      : "Salvando o serviço, aguarde.";

  product.value.mediasToDelete = mediasToDelete.value;
  // product.value.productMedia = productImages.value;
  http
    .put("/product", product.value)
    .then(() => {
      if (productMedias.value.length > 0) {
        saveMedias();
      } else {
        // sucessAlert("Produto/Serviço atualizado com sucesso!");
        router.push({ path: "/produtos-servicos" });
      }
    })
    .catch((err) => {
      console.log(err);
      fieldRequiredAlert("Algo deu errado, tente novamente mais tarde.");
      saving.value = false;
    });
};

// const sucessAlert = (msg) => {
//   swal.fire({
//     toast: true,
//     icon: "success",
//     title: msg,
//     position: "top-right",
//     showConfirmButton: false,
//     timer: 3500,
//     timerProgressBar: true,
//   });
// };

const fieldRequiredAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <Header />

  <div v-show="saving" class="bg-cover">
    <div class="loading white"></div>
    <p class="color-white">{{ savingText }}</p>
  </div>

  <Page>
    <Card class="animated zoomIn">
      <div class="card-holder">
        <div class="card-header">
          <div class="item mobile-hidden">
            <BriefcaseIcon :size="30" fillColor="var(--primary)" />
          </div>
          <span>Cadastro de produtos & serviços</span>
          <router-link
            to="/como-fotografar-produtos"
            class="btn icon ml-auto mt-0"
          >
            <LightBulbIcon fillColor="var(--primary)" />
            <span>Como fotografar meu produto?</span>
          </router-link>
        </div>
        <div v-show="loading" class="flex flex-center mt-2 mb-2">
          <div class="loading"></div>
        </div>
        <div v-show="!loading" class="form">
          <div class="flex flex-start flex-column-mobile">
            <div class="input-item">
              <span>Tipo *</span>
              <div class="input-holder">
                <select v-model="product.type" name="category">
                  <option value="Produto">Produto</option>
                  <option value="Serviço">Serviço</option>
                </select>
                <div class="unit-holder">
                  <ChevronDownIcon fillColor="#959595" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="product.type"
            class="flex flex-between flex-column-mobile mt-2-desktop animated fadeInDown"
          >
            <div class="input-item mt-2-mobile mt-2-tablet">
              <span v-if="product.type == 'Produto'">Nome do produto *</span>
              <span v-else>Nome do serviço *</span>
              <input type="text" v-model="product.name" />
            </div>
            <div class="input-item mt-2-mobile mt-2-tablet">
              <span v-if="product.type == 'Produto'">Resumo do produto *</span>
              <span v-else>Resumo do serviço *</span>
              <input type="text" v-model="product.summary" />
            </div>
          </div>
          <div class="flex flex-between flex-column-mobile mt-2-desktop">
            <div class="input-item mt-2-mobile mt-2-tablet">
              <span>A partir de: *</span>
              <div class="input-holder">
                <CurrencyInput v-model="product.price" />
              </div>
            </div>
            <div class="input-item mt-2-mobile mt-2-tablet">
              <span>Desconto *</span>
              <div class="input-holder">
                <input
                  type="number"
                  min="0"
                  max="100"
                  id="test"
                  v-model="product.discount"
                />
                <div class="unit-holder">
                  <div class="line"></div>
                  <span>%</span>
                </div>
                <span class="desc">
                  * Qual o percentual de desconto deste produto/serviço você
                  oferecerá para os usuários do Festeje aí?
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="!loading"
          class="input-item mt-2-desktop mt-3-mobile mt-2-tablet"
        >
          <span>Descrição do produto/serviço *</span>
          <textarea v-model="product.description"></textarea>
        </div>
        <div v-show="!loading" class="input-item mt-2">
          <span>Orientação *</span>
          <textarea v-model="product.orientation"></textarea>
          <span class="desc">
            * Informe aqui aos usuários quais informações você precisa para
            poder passar o orçamento.
          </span>
        </div>
        <div class="divider"></div>
        <span v-show="!loading" class="title-category"
          >Selecionar categorias *</span
        >
        <div v-show="!loading" class="category-holder mt-1">
          <div
            v-for="category in categories"
            :key="category.id"
            class="category"
            @click="selectCategory(category.id)"
          >
            {{ category.name }}
            <CheckIcon
              :size="26"
              class="check-icon"
              fillColor="var(--green1)"
              :class="{ visible: isSelectedCategory(category.id) }"
            />
          </div>
        </div>
        <div v-show="!loading" class="divider"></div>
        <div v-show="!loading" class="flex">
          <div class="btn icon btn-product-media">
            <span v-show="!loadingImage">Adicionar fotos *</span>
            <ImagesIcon v-show="!loadingImage" />
            <input
              v-show="!loadingImage"
              @change="getFileImages"
              ref="fileImage"
              type="file"
              accept="image/*"
              class="input-files"
              multiple
              @click="$event.target.value = ''"
            />
            <div v-show="loadingImage" class="loading"></div>
          </div>
        </div>
        <p style="position: relative; margin: 8px 0 0 5px; font-style: italic">
          * Máximo de 10mb por imagem
        </p>
        <div v-show="productImages.length > 0 && !loading" class="principal">
          Imagem de capa
        </div>
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            v-show="productImages.length > 0 && !loading"
            class="grid-images"
          >
            <div
              class="product-media"
              v-for="(image, index) in productImages"
              :key="`image${index}`"
            >
              <img
                :class="{ 'border-img': index == 0 }"
                :src="image.source"
                alt="File carousel"
              />
              <div
                v-show="index != 0"
                class="set-principal"
                @click="setCoverImage(image, index)"
              >
                Tornar capa
              </div>
              <div class="remove" @click="removeImage(index, image)">
                Remover
              </div>
            </div>
          </div>
        </transition>
        <div v-show="!loading" class="divider"></div>
        <div class="video-holder">
          <div class="video-desc">
            <span class="video-title">Adicionar videos</span>
            <div class="input-item mt-1">
              <span>Insira o link do video no Youtube aqui</span>
              <div class="input-holder">
                <input type="text" @input="getVideoCode" v-model="videoLink" />
                <div
                  @click="addVideoToArray"
                  class="add-link"
                  title="Adicionar"
                >
                  <CheckIcon fillColor="#fff" />
                </div>
              </div>
            </div>
            <span class="desc">
              * Após colar o link no campo acima, verifique se o video carregou
              corretamente <span class="desc mobile-hidden">ao lado</span>
              <span class="desc desktop-hidden tablet-hidden">abaixo</span> e o
              adicione clicando no botão ✅
            </span>
          </div>
          <div class="player-holder mt-1-mobile">
            <iframe
              :src="`https://www.youtube.com/embed/${videoToShow.source}`"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div
          v-show="productVideos.length > 0 && !loading"
          class="video-added mt-2 animated fadeInDown"
        >
          <span class="video-title">Videos adicionados</span>
          <div class="video-list">
            <div
              class="player-holder mt-1"
              v-for="(video, index) in productVideos"
              :key="`video${index}`"
            >
              <iframe
                :src="`https://www.youtube.com/embed/${video.source}`"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div
                @click="deleteVideo(index)"
                class="video-trash"
                title="Remover video"
              >
                <span class="color-red1">Remover</span>
                <TrashIcon fillColor="var(--red1)" />
              </div>
            </div>
          </div>
        </div>
        <div v-show="!loading" class="divider"></div>

        <!-- <p>{{ productMedias }}</p> -->

        <div
          v-show="!loading"
          class="flex"
          :class="{ 'flex-center': newProduct, 'flex-between': !newProduct }"
        >
          <a
            v-show="!newProduct && !saving"
            :href="`https://app.festeje.ai/#/produto/${product.id}`"
            target="_blank"
            rel="noopener noreferrer"
            class="btn border mt-0 ml-0 mr-0"
          >
            <span>Visualizar página</span>
          </a>
          <div
            v-show="!saving"
            @click="checkProduct"
            class="btn mt-0 ml-0 mr-0"
          >
            <span>Salvar dados</span>
          </div>
        </div>
      </div>
    </Card>
  </Page>
</template>

<style lang="scss" scoped>
@media only screen and (max-width: 720px) {
  .card-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.status {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  gap: 15px;
  .ball {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--secondary);
  }
  span {
    font-size: 0.9rem;
  }
}

.form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .flex-column-mobile {
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      .input-item {
        width: 100%;
      }
    }
  }
  .input-item {
    width: 48%;
  }
  .unit-holder span {
    margin-bottom: 0;
  }
}

.input-item {
  margin: 0;
  span {
    font-family: fontRegular;
    color: var(--primary);
    margin-bottom: 5px;
  }
  textarea {
    border-radius: 12px;
    font-size: 0.95rem;
    padding: 0.35rem 0.85rem;
    background-color: #f0f0f0;
    &::placeholder {
      padding-top: 5px;
      color: var(--primary);
      font-family: fontLight;
      font-size: 0.85rem;
    }
  }
  input {
    position: relative;
    font-family: fontLight;
    font-size: 1rem;
    width: 100%;
    height: 44px;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding-left: 15px;
    border-bottom: none;
  }
  .input-holder {
    height: 44px;
  }
  .desc {
    font-size: 0.9em;
    margin: 0;
    font-family: fontLight;
  }
}

.grid-images {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(280px, 0.1fr));
  gap: 30px;
  margin-top: 4px;
}

.btn-product-media {
  margin: 0;
  .input-files {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.product-media {
  position: relative;
  width: 290px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
  .set-principal {
    position: absolute;
    padding: 6px 12px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 7px;
    left: 7px;
    background-color: #4caf50;
    color: #fff;
    cursor: pointer;
  }
  .remove {
    position: absolute;
    padding: 6px 12px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 7px;
    right: 7px;
    background-color: #a33;
    color: #fff;
    cursor: pointer;
  }
}

.border-img {
  border: 4px solid var(--secondary);
}

.principal {
  position: relative;
  font-size: 1.15em;
  font-weight: 500;
  margin-top: 20px;
  color: var(--secondary);
}

.title-category {
  font-size: 1.15em;
}

.category-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 2em;
  flex-wrap: wrap;
  .category {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    border-radius: 12px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  .check-icon {
    opacity: 0;
    margin-left: 10px;
    &.visible {
      opacity: 1;
    }
  }
}

.video-holder {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
  .video-desc {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    @media only screen and (min-width: 721px) and (max-width: 1159px) {
      width: 43%;
    }
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    .video-title {
      font-size: 1.15em;
    }
    .desc {
      font-size: 0.95em;
      margin: 0;
      margin-top: 5px;
      font-family: fontLight;
    }
  }
  .player-holder {
    position: relative;
    width: 400px;
    height: 200px;
    @media only screen and (min-width: 721px) and (max-width: 1159px) {
      width: 355px;
    }
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .input-holder {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent;
    @media only screen and (min-width: 721px) and (max-width: 1159px) {
      gap: 10px;
    }
    input {
      width: 90% !important;
      @media only screen and (max-width: 720px) {
        width: 80% !important;
        padding: 0 20px 0 15px !important;
      }
    }
    .add-link {
      position: relative;
      width: fit-content;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #20b641;
      padding: 0 8px;
      border-radius: 100%;
      cursor: pointer;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      transition: transform 0.25s ease;
      &:hover {
        transform: translateY(-3px);
      }
      span {
        display: flex;
      }
    }
  }
}

.align-start {
  align-items: flex-start;
}
.color-yellow2 {
  color: #dfb805;
}
.color-orange {
  color: #fa8a4c;
}
.color-red1 {
  color: #e42b2b;
}

.video-added {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .video-title {
    font-size: 1.15em;
  }
  .video-list {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3em;
    flex-wrap: wrap;
    @media only screen and (max-width: 720px) {
      gap: 1em;
    }
    .player-holder {
      position: relative;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      iframe {
        width: 270px;
        height: 150px;
        @media only screen and (max-width: 720px) {
          width: 100%;
        }
      }
    }
    .video-trash {
      position: relative;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
  }
}
</style>
